
import LoginAPI from '../../api/users/login';
import cookies from '../../cookies';
export default {
    name: 'Login',
    data(){
        return {
            errorMsg: '',
            loading: false,
            txtUser: '',
            txtPass: ''
        }
    },
    methods:{
        userkeyup(e){
                if (e.keyCode==13){
                    this.$refs.rPass.focus();
                }
        },
        passkeyup(e){
            if (e.keyCode==13){
                this.login();
            }
        },
        async login(){
            this.loading=true;
            this.errorMsg='';
            LoginAPI.login(this.txtUser,this.txtPass).then((res)=>{
                    var r = res.data;
                  if (typeof(r.banned)=="undefined" || typeof(r.validLogin)=="undefined") this.login_Unexpected();
                  if (r.banned) this.login_banned();
                  if (!r.validLogin) this.login_invalid();
                  //valid login, save token,realname and permissions
                  cookies.setCookie('t',r.token);
                  cookies.setStorage('realname',r.realName);
                  cookies.setStorage('permissions',r.permissions);
                  cookies.setStorage('organisms',r.generalData.organisms);
                  cookies.setStorage('ignoreTypes',r.generalData.ignoreTypes);
                  this.$router.replace('/');
            }).catch(()=>{
                this.login_Unexpected();
            });
        },
        login_Unexpected(){this.loading=false; this.errorMsg='Unexpected error<br>Please check your internet connection and try again later...';},
        login_banned(){this.loading=false; this.errorMsg='Your username is banned<br>Please contact administrator...';this.$refs.rUser.focus();},
        login_invalid(){this.loading=false; this.errorMsg='Invalid username and/or password!'; this.$refs.rUser.focus();},
    }
}